export var themeList = {
  'theme-1': {
    '--prev-bg-menuBar': '#282c34',
    '--prev-bg-menuBarColor': '#fff',
    '--prev-color-primary': '#4073FA',
    '--prev-color-text-white': '#fff',
    '--prev-bg-menu-hover-ba-color': '#4073FA',
    '--prev-bg-topBar': '#fff',
    //顶部菜单栏颜色
    '--prev-bg-topBarColor': '#282c34',
    '--prev-bg-columnsMenuBar': '#282c34',
    '--prev-bg-columnsMenuBarColor': '#fff',
    '--prev-MenuActiveColor': '#4073FA',
    '--prev-tag-active-color': '#4073FA',
    '--prev-color-primary-light-8': '#F2F6FF',
    '--prev-color-primary-light-9': '#E5EEFF',
    '--prev-bg-columnsMenuActiveColor': '#fff'
  },
  'theme-2': {
    '--prev-bg-menuBar': '#ffffff',
    '--prev-bg-menuBarColor': '#fff',
    '--prev-color-primary': '#4073FA',
    '--prev-color-text-white': '#fff',
    '--prev-bg-menu-hover-ba-color': '#E5EEFF',
    '--prev-bg-topBar': '#ffffff',
    '--prev-bg-topBarColor': '#515a6e',
    '--prev-bg-columnsMenuBar': '#fff',
    '--prev-bg-columnsMenuBarColor': '#515a6e',
    '--prev-bg-columnsMenuActiveColor': '#fff',
    '--prev-MenuActiveColor': '#4073FA',
    '--prev-bg-columnsMenuActiveBgColor': '#4073FA',
    '--prev-tag-active-color': '#4073FA',
    '--prev-color-primary-light-9': '#E5EEFF'
  },
  'theme-3': {
    '--prev-bg-menuBar': '#282c34',
    '--prev-bg-menuBarColor': '#fff',
    '--prev-color-primary': '#006d7a',
    '--prev-color-text-white': '#fff',
    '--prev-bg-menu-hover-ba-color': '#006d7a',
    // 菜单选中背景色
    '--prev-bg-topBar': '#fff',
    //顶部菜单栏颜色
    '--prev-bg-topBarColor': '#282c34',
    '--prev-bg-columnsMenuBar': '#282c34',
    '--prev-bg-columnsMenuBarColor': '#fff',
    '--prev-bg-columnsMenuActiveColor': '#fff',
    '--prev-MenuActiveColor': '#006d7a',
    '--prev-color-primary-light-9': '#006d7a',
    '--prev-tag-active-color': '#006d7a'
  },
  'theme-4': {
    '--prev-bg-menuBar': '#03a1ae',
    '--prev-bg-menuBarColor': '#fff',
    '--prev-color-primary': '#41b584',
    '--prev-color-text-white': '#fff',
    '--prev-bg-menu-hover-ba-color': '#006d7a',
    '--prev-bg-topBar': '#ffffff',
    '--prev-bg-topBarColor': '#515a6e',
    '--prev-bg-columnsMenuBar': '#fff',
    '--prev-bg-columnsMenuBarColor': '#515a6e',
    '--prev-bg-columnsMenuActiveColor': '#fff',
    '--prev-MenuActiveColor': '#fff',
    '--prev-tag-active-color': '#fff',
    '--prev-color-primary-light-9': '#006d7a'
  },
  'theme-5': {
    '--prev-bg-menuBar': '#282c34',
    '--prev-bg-menuBarColor': '#fff',
    '--prev-color-primary': '#6954f0',
    '--prev-color-text-white': '#fff',
    '--prev-bg-menu-hover-ba-color': '#6954f0',
    // 菜单选中背景色
    '--prev-bg-topBar': '#fff',
    //顶部菜单栏颜色
    '--prev-bg-topBarColor': '#282c34',
    '--prev-bg-columnsMenuBar': '#282c34',
    '--prev-bg-columnsMenuBarColor': '#fff',
    '--prev-bg-columnsMenuActiveColor': '#fff',
    '--prev-MenuActiveColor': '#6954f0',
    '--prev-color-primary-light-9': '#f0eefe',
    '--prev-tag-active-color': '#6954f0'
  },
  'theme-6': {
    '--prev-bg-menuBar': '#ffffff',
    '--prev-bg-menuBarColor': '#515a6e',
    '--prev-color-primary': '#6954f0',
    '--prev-color-text-white': '#fff',
    '--prev-bg-menu-hover-ba-color': '#E5EEFF',
    '--prev-bg-topBar': '#ffffff',
    '--prev-bg-topBarColor': '#515a6e',
    '--prev-bg-columnsMenuBar': '#fff',
    '--prev-bg-columnsMenuBarColor': '#515a6e',
    '--prev-bg-columnsMenuActiveColor': '#fff',
    '--prev-MenuActiveColor': '#6954f0',
    '--prev-color-primary-light-9': '#f0eefe',
    '--prev-tag-active-color': '#6954f0'
  },
  'theme-7': {
    '--prev-bg-menuBar': '#282c34',
    '--prev-bg-menuBarColor': '#fff',
    '--prev-color-primary': '#f34d37',
    '--prev-color-text-white': '#fff',
    '--prev-bg-menu-hover-ba-color': '#f34d37',
    // 菜单选中背景色
    '--prev-bg-topBar': '#fff',
    //顶部菜单栏颜色
    '--prev-bg-topBarColor': '#282c34',
    '--prev-bg-columnsMenuBar': '#282c34',
    '--prev-bg-columnsMenuBarColor': '#fff',
    '--prev-bg-columnsMenuActiveColor': '#fff',
    '--prev-MenuActiveColor': '#f34d37',
    '--prev-tag-active-color': '#f34d37'
  },
  'theme-8': {
    '--prev-bg-menuBar': '#ffffff',
    '--prev-bg-menuBarColor': '#515a6e',
    '--prev-color-primary': '#f34d37',
    '--prev-color-text-white': '#fff',
    '--prev-bg-menu-hover-ba-color': '#feedeb',
    '--prev-bg-topBar': '#ffffff',
    '--prev-bg-topBarColor': '#515a6e',
    '--prev-bg-columnsMenuBar': '#fff',
    '--prev-bg-columnsMenuBarColor': '#515a6e',
    '--prev-bg-columnsMenuActiveColor': '#fff',
    '--prev-color-primary-light-9': '#feedeb',
    '--prev-MenuActiveColor': '#f34d37',
    '--prev-tag-active-color': '#f34d37'
  },
  'theme-9': {
    '--prev-bg-menuBar': '#ffffff',
    '--prev-bg-menuBarColor': '#515a6e',
    '--prev-color-primary': '#4073FA',
    '--prev-color-text-white': '#fff',
    '--prev-bg-menu-hover-ba-color': '#E5EEFF',
    '--prev-bg-topBar': '#ffffff',
    '--prev-bg-topBarColor': '#515a6e',
    '--prev-bg-columnsMenuBar': 'linear-gradient(90deg,#006cff,#399efd)',
    '--prev-bg-columnsMenuBarColor': '#fff',
    '--prev-bg-columnsMenuActiveColor': '#fff',
    '--prev-tag-active-color': '#4073FA',
    '--prev-MenuActiveColor': '#4073FA',
    '--prev-color-primary-light-9': '#E5EEFF'
  }
};